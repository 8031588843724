<div class="row">
  <ng-container *ngIf="isIDI">
    <div class="col-xs-4 col-sm-4" *ngIf="court.type === 'Bankruptcy'">
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Case Number'" [value]="court.caseNumber"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Chapter'" [value]="court.chapter"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Filing Date'" [value]="court.filingDate"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Case Status'" [value]="court.caseStatus"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Case Status Date'" [value]="court.caseStatusDate"></app-member-people-search-value>
    </div>

    <div class="col-xs-4 col-sm-4" *ngIf="court.type === 'Lien'">
      <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Document Location'" [value]="''"></app-member-people-search-value>
      <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Document Location' : ''" [value]="record.documentLocation?.fullNumber"></app-member-people-search-value>

      <app-member-people-search-value *ngIf="court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Date'" [value]="court.ljdRecord[0].date"></app-member-people-search-value>
      <app-member-people-search-value *ngFor="let record of court.ljdRecord.slice(1)" [classes]="'col-xs-12 col-sm-12'" [title]="" [value]="record.date"></app-member-people-search-value>
      <app-member-people-search-value *ngIf="!court.ljdRecord.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Date'" [value]="''"></app-member-people-search-value>

      <app-member-people-search-value *ngIf="court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Recording Date'" [value]="court.ljdRecord[0].recordingDate"></app-member-people-search-value>
      <app-member-people-search-value *ngFor="let record of court.ljdRecord.slice(1)" [classes]="'col-xs-12 col-sm-12'" [title]="" [value]="record.recordingDate"></app-member-people-search-value>
      <app-member-people-search-value *ngIf="!court.ljdRecord.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Recording Date'" [value]="''"></app-member-people-search-value>

      <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'County'" [value]="''"></app-member-people-search-value>
      <ng-container *ngFor="let ljdInfo of court.ljdInfo; let i = index">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'County' : ''" [value]="ljdInfo.caseCounty"></app-member-people-search-value>
      </ng-container>

      <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'State'" [value]="''"></app-member-people-search-value>
      <ng-container *ngFor="let ljdInfo of court.ljdInfo; let i = index">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'State' : ''" [value]="ljdInfo.caseState"></app-member-people-search-value>
      </ng-container>

      <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Amount'" [value]="''"></app-member-people-search-value>
      <ng-container *ngFor="let ljdInfo of court.ljdInfo; let i = index">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Amount' : ''" [value]="ljdInfo.amount | currency : 'USD' : 'symbol' : '1.0'"></app-member-people-search-value>
      </ng-container>
    </div>

    <div class="col-xs-4 col-sm-4" *ngIf="court.type === 'Judgment'">
      <app-member-people-search-value *ngIf="court.courtCaseNumbers?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Case Number'" [value]="court.courtCaseNumbers[0]"></app-member-people-search-value>
      <app-member-people-search-value *ngFor="let caseNumber of court.courtCaseNumbers?.slice(1)" [classes]="'col-xs-12 col-sm-12'" [title]="" [value]="caseNumber"></app-member-people-search-value>
      <app-member-people-search-value *ngIf="!court.courtCaseNumbers?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Case Numbers'" [value]="''"></app-member-people-search-value>

      <app-member-people-search-value *ngIf="court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Date'" [value]="court.ljdRecord[0].date"></app-member-people-search-value>
      <app-member-people-search-value *ngFor="let record of court.ljdRecord.slice(1)" [classes]="'col-xs-12 col-sm-12'" [title]="" [value]="record.date"></app-member-people-search-value>
      <app-member-people-search-value *ngIf="!court.ljdRecord.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Date'" [value]="''"></app-member-people-search-value>

      <app-member-people-search-value *ngIf="court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Recording Date'" [value]="court.ljdRecord[0].recordingDate"></app-member-people-search-value>
      <app-member-people-search-value *ngFor="let record of court.ljdRecord.slice(1)" [classes]="'col-xs-12 col-sm-12'" [title]="" [value]="record.recordingDate"></app-member-people-search-value>
      <app-member-people-search-value *ngIf="!court.ljdRecord.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Recording Date'" [value]="''"></app-member-people-search-value>

      <app-member-people-search-value *ngIf="!findRecord(court.plaintiff, 'names')" [classes]="'col-xs-12 col-sm-12'" [title]="'Plaintiff'" [value]="''"></app-member-people-search-value>
      <ng-container *ngFor="let plaintiff of court.plaintiff; let i = index">
        <app-member-people-search-value *ngFor="let name of plaintiff.names; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Plaintiff' : ''" [value]="name.fullName"></app-member-people-search-value>
      </ng-container>

      <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'County'" [value]="''"></app-member-people-search-value>
      <ng-container *ngFor="let ljdInfo of court.ljdInfo; let i = index">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'County' : ''" [value]="ljdInfo.caseCounty"></app-member-people-search-value>
      </ng-container>

      <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'State'" [value]="''"></app-member-people-search-value>
      <ng-container *ngFor="let ljdInfo of court.ljdInfo; let i = index">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'State' : ''" [value]="ljdInfo.caseState"></app-member-people-search-value>
      </ng-container>

      <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Amount'" [value]="''"></app-member-people-search-value>
      <ng-container *ngFor="let ljdInfo of court.ljdInfo; let i = index">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Amount' : ''" [value]="ljdInfo.amount | currency : 'USD' : 'symbol' : '1.0'"></app-member-people-search-value>
      </ng-container>
    </div>
  
    <div class="col-xs-8 col-sm-8">
      <ng-container *ngIf="court.type === 'Lien'">
        <app-member-people-search-value *ngIf="!court.creditors?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Creditor'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let creditor of court.creditors; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Creditors' : ''" [value]="creditor?.fullName"></app-member-people-search-value>
        
        <app-member-people-search-value *ngIf="!court.lienTypes?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Lien Type'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let lienType of court.lienTypes; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Lien Type' : ''" [value]="lienType"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Original Document Location'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Original Document Location' : ''" [value]="record.originalDocumentLocation?.fullNumber"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Fips Code'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Fips Code' : ''" [value]="info.fipsCode"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Federal Tax Area'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Federal Tax Area' : ''" [value]="info.federalTax?.area"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Federal Tax City'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Federal Tax City' : ''" [value]="info.federalTax?.city"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Federal Tax State'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Federal Tax State' : ''" [value]="info.federalTax?.state"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Back Support Amount'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Back Support Amount' : ''" [value]="info.backSupportAmount | currency : 'USD' : 'symbol' : '1.0'"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Deed Category'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Deed Category' : ''" [value]="record.deedCategory"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Case Description'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Case Description' : ''" [value]="record.caseDescription"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Damar Type'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Damar Type' : ''" [value]="record.damarType"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Original Recording Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Original Recording Date' : ''" [value]="record.origRecordingDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Tax Period Max'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Tax Period Max' : ''" [value]="record.taxPeriodMax"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Tax Period Min'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Tax Period Min' : ''" [value]="record.taxPeriodMin"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Refile Extend Last Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Refile Extend Last Date' : ''" [value]="record.refileExtendLastDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Abstract Issue Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Abstract Issue Date' : ''" [value]="record.abstractIssueDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Stay Ordered'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Stay Ordered' : ''" [value]="record.stayOrdered"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Stay Ordered Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Stay Ordered Date' : ''" [value]="record.stayOrderedDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Document Filing Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Document Filing Date' : ''" [value]="record.documentFilingDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Original Document Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Original Document Date' : ''" [value]="record.origDocumentDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.attorneies?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let attorney of court.attorneies; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Attorney' : ''" [value]="attorney"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="court.courtCaseNumbers?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Case Number'" [value]="court.courtCaseNumbers[0]"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let caseNumber of court.courtCaseNumbers?.slice(1)" [classes]="'col-xs-12 col-sm-12'" [title]="" [value]="caseNumber"></app-member-people-search-value>
        <app-member-people-search-value *ngIf="!court.courtCaseNumbers?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Case Numbers'" [value]="''"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="court.taxCertificationNumbers?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Tax Certification Number'" [value]="court.taxCertificationNumbers[0]"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let caseNumber of court.taxCertificationNumbers?.slice(1)" [classes]="'col-xs-12 col-sm-12'" [title]="" [value]="caseNumber"></app-member-people-search-value>
        <app-member-people-search-value *ngIf="!court.taxCertificationNumbers?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Tax Certification Number'" [value]="''"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.issuingAgency?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Issuing Agency'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let issuingAgency of court.issuingAgency; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Issuing Agency' : ''" [value]="issuingAgency"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.businesses?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Business'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let business of court.businesses; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Business' : ''" [value]="business"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.hoaAddress?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Hoa Address'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let address of court.hoaAddress; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Hoa Address' : ''" [value]="address"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!findRecord(court.debtor, 'taxPayerId')" [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Tax Payer Id'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let debtor of court.debtor; let i = index">
          <app-member-people-search-value *ngFor="let taxPayerId of debtor.taxPayerId; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Debtor Tax Payer Id' : ''" [value]="taxPayerId"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.debtor, 'address')" [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Address'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let debtor of court.debtor; let i = index">
          <app-member-people-search-value *ngFor="let address of debtor.address; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Debtor Address' : ''" [value]="address"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.debtor, 'foreignAddress')" [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Foreign Address'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let debtor of court.debtor; let i = index">
          <app-member-people-search-value *ngFor="let address of debtor.foreignAddress; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Debtor Foreign Address' : ''" [value]="address"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.debtor, 'names')" [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Name'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let debtor of court.debtor; let i = index">
          <app-member-people-search-value *ngFor="let name of debtor.names; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Debtor Name' : ''" [value]="name.fName"></app-member-people-search-value>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="court.type === 'Judgment'">
        <app-member-people-search-value *ngIf="!court.creditors?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Creditor'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let creditor of court.creditors; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Document Location' : ''" [value]="creditor?.fullName"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Document Location'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Document Location' : ''" [value]="record.documentLocation?.fullNumber"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Original Document Location'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Original Document Location' : ''" [value]="record.originalDocumentLocation?.fullNumber"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Fips Code'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Fips Code' : ''" [value]="info.fipsCode"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Federal Tax Area'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Federal Tax Area' : ''" [value]="info.federalTax?.area"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Federal Tax City'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Federal Tax City' : ''" [value]="info.federalTax?.city"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Federal Tax State'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Federal Tax State' : ''" [value]="info.federalTax?.state"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdInfo?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Back Support Amount'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let info of court.ljdInfo; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Back Support Amount' : ''" [value]="info.backSupportAmount | currency : 'USD' : 'symbol' : '1.0'"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Deed Category'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Deed Category' : ''" [value]="record.deedCategory"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Case Description'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Case Description' : ''" [value]="record.caseDescription"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Damar Type'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Damar Type' : ''" [value]="record.damarType"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Original Recording Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Original Recording Date' : ''" [value]="record.origRecordingDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Tax Period Max'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Tax Period Max' : ''" [value]="record.taxPeriodMax"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Tax Period Min'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Tax Period Min' : ''" [value]="record.taxPeriodMin"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Refile Extend Last Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Refile Extend Last Date' : ''" [value]="record.refileExtendLastDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Abstract Issue Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Abstract Issue Date' : ''" [value]="record.abstractIssueDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Stay Ordered'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Stay Ordered' : ''" [value]="record.stayOrdered"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Stay Ordered Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Stay Ordered Date' : ''" [value]="record.stayOrderedDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Document Filing Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Document Filing Date' : ''" [value]="record.documentFilingDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.ljdRecord?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Original Document Date'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let record of court.ljdRecord; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Original Document Date' : ''" [value]="record.origDocumentDate"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.attorneies?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let attorney of court.attorneies; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Attorney' : ''" [value]="attorney"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!court.businesses?.length" [classes]="'col-xs-12 col-sm-12'" [title]="'Business'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let business of court.businesses; let i = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 ? 'Business' : ''" [value]="business"></app-member-people-search-value>

        <app-member-people-search-value *ngIf="!findRecord(court.plaintiff, 'taxPayerId')" [classes]="'col-xs-12 col-sm-12'" [title]="'Plaintiff Tax Payer Id'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let plaintiff of court.plaintiff; let i = index">
          <app-member-people-search-value *ngFor="let taxPayerId of plaintiff.taxPayerId; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Plaintiff Tax Payer Id' : ''" [value]="taxPayerId"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.plaintiff, 'address')" [classes]="'col-xs-12 col-sm-12'" [title]="'Plaintiff Address'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let plaintiff of court.plaintiff; let i = index">
          <app-member-people-search-value *ngFor="let address of plaintiff.address; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Plaintiff Address' : ''" [value]="address"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.plaintiff, 'foreignAddress')" [classes]="'col-xs-12 col-sm-12'" [title]="'Plaintiff Foreign Address'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let plaintiff of court.plaintiff; let i = index">
          <app-member-people-search-value *ngFor="let address of plaintiff.foreignAddress; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Plaintiff Foreign Address' : ''" [value]="address"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.defendant, 'names')" [classes]="'col-xs-12 col-sm-12'" [title]="'Defendant Name'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let defendant of court.defendant; let i = index">
          <app-member-people-search-value *ngFor="let name of defendant.names; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Defendant Name' : ''" [value]="name.fullName"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.defendant, 'taxPayerId')" [classes]="'col-xs-12 col-sm-12'" [title]="'Defendant Tax Payer Id'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let defendant of court.defendant; let i = index">
          <app-member-people-search-value *ngFor="let taxPayerId of defendant.taxPayerId; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Defendant Tax Payer Id' : ''" [value]="taxPayerId"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.defendant, 'address')" [classes]="'col-xs-12 col-sm-12'" [title]="'Defendant Address'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let defendant of court.defendant; let i = index">
          <app-member-people-search-value *ngFor="let address of defendant.address; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Defendant Address' : ''" [value]="address"></app-member-people-search-value>
        </ng-container>

        <app-member-people-search-value *ngIf="!findRecord(court.defendant, 'foreignAddress')" [classes]="'col-xs-12 col-sm-12'" [title]="'Defendant Foreign Address'" [value]="''"></app-member-people-search-value>
        <ng-container *ngFor="let defendant of court.defendant; let i = index">
          <app-member-people-search-value *ngFor="let address of defendant.foreignAddress; let j = index" [classes]="'col-xs-12 col-sm-12'" [title]="i === 0 && j === 0 ? 'Defendant Foreign Address' : ''" [value]="address"></app-member-people-search-value>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="court.type === 'Bankruptcy'">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Asset Indicator'" [value]="court.assets"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Converted'" [value]="court.converted"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Converted Date'" [value]="court.convertedDate"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Date Collected'" [value]="court.dateCollected"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Voluntary Flag'" [value]="court.voluntaryFlag"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Judge Name'" [value]="court.judge"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Judge Initials'" [value]="court.judgeInitials"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Last Date To File POC'" [value]="court.lastDateToFilePoc"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'ProSe Indicator'" [value]="court.proSeIndicator"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Screen'" [value]="court.screen"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney Address'" [value]="court.attorneyAddress"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney Name'" [value]="court.attorneyName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney Phone'" [value]="court.attorneyPhone"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney Law Firm'" [value]="court.attorneyLawFirm"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Court District'" [value]="court.courtDistrict"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Court Division'" [value]="court.courtDivision"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Court Number'" [value]="court.courtNumber"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Court Name'" [value]="court.courtName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor A Address'" [value]="court.debtorA.address"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor A Name'" [value]="court.debtorA.fullName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor A Indicator'" [value]="court.debtorA.indicator"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor A SSN Tid'" [value]="court.debtorA.ssnTid"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor A Tid'" [value]="court.debtorA.tid"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor B Address'" [value]="court.debtorB.address"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor B Name'" [value]="court.debtorB.fullName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor B Indicator'" [value]="court.debtorB.indicator"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor B SSN Tid'" [value]="court.debtorB.ssnTid"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor B Tid'" [value]="court.debtorB.tid"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Trustee Address'" [value]="court.trusteeAddress"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Trustee Name'" [value]="court.trusteeName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Trustee Phone'" [value]="court.trusteePhone"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Meeting Address'" [value]="court.meetingAddress"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Meeting Date'" [value]="court.meetingDate"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Meeting Phone'" [value]="court.meetingPhone"></app-member-people-search-value>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!isIDI">
    <div class="col-xs-4 col-sm-4">
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Case Number'" [value]="court.nullId?null:court.id"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Court ID'" [value]="court.courtId"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Tax ID'" [value]="court.taxId"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Record Type'" [value]="court.recordType"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Document Type'" [value]="court.documentType"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Report Date'" [value]="court.reportDate"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Filing State'" [value]="court.filingState"></app-member-people-search-value>
    </div>
  
    <div class="col-xs-8 col-sm-8">
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Case Status'" [value]="court.caseStatus"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Assets'" [value]="court.assets | currency : 'USD' : 'symbol' : '1.0'"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Judgment Amount'" [value]="court.judgmentAmount | currency : 'USD' : 'symbol' : '1.0'"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Court'" [value]="court.court"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Court Phone'" [value]="court.courtPhone"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Judge'" [value]="court.judge"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Judge Initials'" [value]="court.judgeInitials"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Filing Office'" [value]="court.filingOffice"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Schedule 341 Meeting Date'" [value]="court.schedule341MeetingDate"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Filing Date'" [value]="court.filingDate"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Filing Type'" [value]="court.filingType"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Filing Type Desc'" [value]="court.filingTypeDesc"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Original Lien Date'" [value]="court.originalLienDate"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Discharged Date'" [value]="court.dischargedDate"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Bankruptcy Date'" [value]="court.bankruptcyDate"></app-member-people-search-value>
  
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Name'"
                                      [value]="court.debtorFullName?court.debtorFullName: (court.debtorFName + ' ' + court.debtorMName  + ' ' + court.debtorLName)"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Address'"
                                      [value]="court.debtorAddress?court.debtorAddress:(
                                      court.debtorStreet1?(court.debtorStreet1 + ', '):'' +
                                      court.debtorCity?(court.debtorCity + ', '):'' +
                                      court.debtorState?(court.debtorState + ', '):'' +
                                      court.debtorZip)"></app-member-people-search-value>
      <ng-container *ngFor="let obj of court.debtors">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Name'" [value]="obj.fullName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Debtor Address'" [value]="obj.fullAddress"></app-member-people-search-value>
      </ng-container>
  
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Creditor Name'"
                                      [value]="court.creditorFullName?court.creditorFullName:(court.creditorFName + ' ' + court.creditorMName + ' ' + court.creditorLName)"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Lien Issuer Name'" [value]="court.lienIssuerName"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Creditor Type'" [value]="court.creditorType"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Law firm'" [value]="court.lawFirm"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Plaintiff law firm'" [value]="court.plaintiffLawFirm"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney A'" [value]="court.attorneyA"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney B'" [value]="court.attorneyB"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Attorney Phone'" [value]="court.attorneyPhone"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'County'" [value]="court.county"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Trustee Name'" [value]="court.trusteeName"></app-member-people-search-value>
      <app-member-people-search-value [classes]="'col-xs-12 col-sm-12'" [title]="'Trustee Phone/Email'" [value]="court.trusteePhoneEmail"></app-member-people-search-value>
    </div>
  </ng-container>
</div>
