<div class="mini-reports-header row">
  <div class="col-md-6 col-sm-6 col-xs-9 mini-reports-header__desc">
    <h2>Reverse Phone Report</h2>
    <p>
      View important personal details about {{ person?.fName }}
      {{ person?.lName }} here. You can also add a note about
      {{ person?.fName }} or save this report for easy access later.
    </p>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-3 mini-reports-header__img">
    <div class="icon-wrapper">
      <img src="assets/ux1/search_flow/ic-favorite.svg" />
      <img src="assets/ux1/search_flow/ic-profile.svg" />
      <img src="assets/ux1/search_flow/ic-faq.svg" />
    </div>
  </div>
</div>


<div class="mini-reports-body">
  <div class="mini-reports-body-diagram">
    <div class="mini-reports-body-diagram__back">
      <img src="assets/ux1/search_flow/ic-diagram-user.svg" />
    </div>
    <div class="mini-reports-body-diagram__back">
      <p>{{ person?.fName }} {{ person?.lName }}</p>
    </div>
    <div class="mini-reports-body-diagram__content">
      <div class="info-item info-item--birth">
        <div class="item-text">
          <p>Date of Birth</p>
          <p>{{ person?.dob }}</p>
        </div>
        <img src="assets/ux1/search_flow/ic-diagram-birth.svg" />
        <div class="small-circle"></div>
        <div class="smaller-circle"></div>
      </div>

      <div class="info-item info-item--age">
        <div class="item-text">
          <p>Age</p>
          <p>{{ person?.age }}</p>
        </div>
        <img src="assets/ux1/search_flow/ic-diagram-age.svg" />
        <div class="small-circle"></div>
        <div class="smaller-circle"></div>
      </div>

      <div class="info-item info-item--location">
        <div class="item-text">
          <p>Location</p>
          <p>{{ person?.cityState }}</p>
        </div>
        <img src="assets/ux1/search_flow/ic-diagram-location.svg" />
        <div class="small-circle"></div>
        <div class="smaller-circle"></div>
      </div>

      <div class="info-item info-item--zodiac">
        <div class="item-text">
          <p>Zodiac Sign</p>
          <p>{{ person?.tempClient?.horoscope?.sign }}</p>
        </div>
        <img src="assets/ux1/search_flow/ic-diagram-zodiac.svg" />
        <div class="small-circle"></div>
        <div class="smaller-circle"></div>
      </div>

      <div class="info-item info-item--phone">
        <div class="item-text">
          <p>Phone Number</p>
          <p>(954) 555-1234</p>
          <p>
            <a [routerLink]="[href]" fragment="phoneNumber">See More</a>
          </p>
        </div>
        <img src="assets/ux1/search_flow/ic-diagram-phone.svg" />
        <div class="small-circle"></div>
        <div class="smaller-circle"></div>
      </div>

      <div class="info-item info-item--email">
        <div class="item-text">
          <p>Email Address</p>

          <ng-container *ngIf="getEmails().length > 0; else noEmail">
            <div style="position: absolute" class="item-text-emails">
              <ng-container *ngIf="showAllEmail; else allEmail">
                <p *ngFor="let email of getEmails()">{{ email }}</p>
              </ng-container>
              <ng-template #allEmail>
                <p>{{ getEmails()[0] }}</p>
              </ng-template>
              <p *ngIf="getEmails().length > 1" class="btn-see-more">
                <a (click)="showAllEmail = true;">
                  <!-- (click)="switchShowAllEmail()" -->
                  See more
                </a>
              </p>
            </div>
          </ng-container>

          <ng-template #noEmail>
            <p>Not Available for {{ person?.fName }}</p>
          </ng-template>
        </div>
        <img src="assets/ux1/search_flow/ic-diagram-email.svg" />
        <div class="small-circle"></div>
        <div class="smaller-circle"></div>
      </div>
    </div>
  </div>

  <div>
    <b>ZODIAC SIGN</b>{{person?.tempClient?.horoscope?.sign }}
  </div>
</div>