<div id="{{page}}" class="theme-legacy {{pageType}} {{pageCategory}}">
  <app-member-header [uxHelper]="uxHelper" [page]="page" [pageType]="pageType" [pageCategory]="pageCategory"></app-member-header>
  <ng-container *ngIf="uxHelper.hasUxComposite()">

    <ng-container *ngIf="this.user">
      <div class="container-fluid account-wrapper">
        <div class="container">
          <div class="row">

            <div class="col-md-10 col-md-offset-1 account-header">
              <div class="row">
                <div class="col-xs-12">
                  <h1>Update Password</h1>
                </div>
              </div>
            </div><!--/.account-header -->

            <div class="col-md-10 col-md-offset-1 account-content">
              <div class="row">
                <div class="col-xs-12 col-sm-9 main">
                  <div>
                    <div class="row">
                      <div class="col-xs-6">
                        <div class="form-group" [class.has-error]="!isValid()">
                          <label for="password">New Password</label>
                          <input type="password" class="form-control" id="password" placeholder="Password" [(ngModel)]="newPassword">
                          <span id="helpBlock" class="help-block">Minimum 8 characters, with one letter and one number.</span>
                        </div>
                      </div>
                      <div class="col-xs-6">
                        <div class="form-group" [class.has-error]="!(newPassword === confirmPassword)">
                          <label for="passwordConfirm">Confirm password</label>
                          <input type="password" class="form-control" id="passwordConfirm" placeholder="Password confirm" [(ngModel)]="confirmPassword">
                        </div>
                      </div>

                      <div class="col-xs-12 text-center">
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary" (click)="update()"
                                  [disabled]="!(isValid())">SAVE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-3 secondary">
                  <h4>
                    <uxtemplate [uxHelper]="uxHelper" [key]="uxHelper.getUxcompKey('rhs.title')"></uxtemplate>
                  </h4>
                  <div>
                    <uxtemplate [uxHelper]="uxHelper" [key]="uxHelper.getUxcompKey('rhs.content')"></uxtemplate>
                  </div>
                </div>

              </div>
            </div><!--/.account-content -->

          </div>
        </div>
      </div><!--/.member-wrapper -->


    </ng-container>


    <app-member-footer [uxHelper]="uxHelper"></app-member-footer>
    <app-member-disclaimer [uxHelper]="uxHelper"></app-member-disclaimer>
  </ng-container>
</div>
<app-member-closure></app-member-closure>


<dev-message [uxHelper]="uxHelper">
</dev-message>
