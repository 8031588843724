<link type="text/css" rel="stylesheet" href="/assets/common/roboto/fonts.css" media="screen,print">
<link type="text/css" rel="stylesheet" href="/assets/common/fontawesome-free-5.3.1-web/css/all.min.css" media="screen,print">
<link type="text/css" rel="stylesheet" href="/assets/common/glyphicons/css/glyphicons.css" media="screen,print">

<ng-container *ngIf="site == 'truthviewer'">
  <!--  import from CSS file does not work with juice css inliner-->
  <link type="text/css" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,700" media="screen,print">
  <link type="text/css" rel="stylesheet" href="/assets/truthviewer/style.css" media="screen,print">
</ng-container>

<router-outlet></router-outlet>

<ng-container *ngIf="serviceHelperService.spinnerService.isSpinning()">
  <div id="spinner-wrapper" style="position: fixed;top:0;bottom:0;left:0;right:0;margin:0;padding:0;background: rgba(0,0,0,0.3);">
    <div id="spinner" style="position: fixed;top:50%;left:50%;transform: translate(-50%, -50%);z-index: 100; margin:0;padding:0;">
      <img
        src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwcHgiICBoZWlnaHQ9IjIwMHB4IiAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiIGNsYXNzPSJsZHMtZWNsaXBzZSIgc3R5bGU9ImJhY2tncm91bmQ6IG5vbmU7Ij4gICAgPHBhdGggbmctYXR0ci1kPSJ7e2NvbmZpZy5wYXRoQ21kfX0iIG5nLWF0dHItZmlsbD0ie3tjb25maWcuY29sb3J9fSIgc3Ryb2tlPSJub25lIiBkPSJNMTAgNTBBNDAgNDAgMCAwIDAgOTAgNTBBNDAgNDQgMCAwIDEgMTAgNTAiIGZpbGw9IiNmM2RjYjIiIHRyYW5zZm9ybT0icm90YXRlKDI5MiA1MCA1MikiPiAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTI7MzYwIDUwIDUyIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjNzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+ICAgIDwvcGF0aD4gIDwvc3ZnPg=='>
    </div>
  </div>
</ng-container>

<div *ngIf="toastrService.toastrs.length" class="toast-wrapper">
  <div *ngFor="let toast of toastrService.toastrs" class="toastr {{ toast.type }}" (click)="toastrService.close(toast)">
    <h3 class="toastr__title">{{toast.title}}</h3>
    <p class="toastr__content">{{toast.message}}</p>
  </div>
</div>
<!--<ng-container>-->
<!--  <div id="snackbars-top" class="snackbars" *ngIf="serviceHelperService.alertService.messages.length">-->
<!--    <ng-container *ngFor="let message of serviceHelperService.alertService.messages">-->
<!--      <ng-container *ngIf="message.position === serviceHelperService.alertService.POSITION.top">-->
<!--        <div class="snackbar snackbar-top {{message.type}} {{message.class}}" [style.transition-duration]="serviceHelperService.alertService.easeDuration/1000+'s'">-->
<!--          <rawtemplate [html]="message.message"></rawtemplate>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </ng-container>-->
<!--  </div>-->

<!--  <div id="snackbars-bottom" class="snackbars" *ngIf="serviceHelperService.alertService.messages.length">-->
<!--    <ng-container *ngFor="let message of serviceHelperService.alertService.messages">-->
<!--      <ng-container *ngIf="message.position === serviceHelperService.alertService.POSITION.bottom">-->
<!--        <div class="snackbar snackbar-bottom {{message.type}} {{message.class}}" [style.transition-duration]="serviceHelperService.alertService.easeDuration/1000+'s'">-->
<!--          <rawtemplate [html]="message.message"></rawtemplate>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--</ng-container>-->
