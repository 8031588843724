<style>
  .unsubscribe {
    border: 1px solid #555;
    border-radius: 2px;
    color: #333;
  }
</style>

<div id="{{page}}" class="theme-legacy theme-{{uxHelper.getTheme()}} article-like {{pageType}} {{pageCategory}}">
  <ng-container *ngIf="uxHelper.hasUxComposite()">
    <div class="review">
      <app-sales-header [uxHelper]="uxHelper"></app-sales-header>
      <div class="container-fluid content-wrapper signup">
        <div class="container">

          <div class="row text-center">
            <div class="col-sm-12 loader-header">
              <h1>
                <uxtemplate [uxHelper]="uxHelper" [key]="uxHelper.getUxcompKey('headline')"></uxtemplate>
              </h1>
              <p>
                <uxtemplate [uxHelper]="uxHelper" [key]="uxHelper.getUxcompKey('headline.sub')"></uxtemplate>
              </p>
            </div>
          </div><!-- /row -->

          <div class="row">
            <div class="col-md-10 col-md-offset-1">
              <div class="row">
                <div class="col-md-8 content-box">
                  <div class="row">
                    <div class="col-xs-12" *ngIf="isValid()">
                      <ng-container *ngIf="unsubscribed">
                        You are unsubscribed.
                      </ng-container>
                      <ng-container *ngIf="!unsubscribed">
                        Do you want to unsubscribe?
                        <button class="unsubscribe" [disabled]="!isValid()" (click)="unsubscribe()">Unsubscribe</button>
                      </ng-container>
                    </div>
                    <div class="col-xs-12" *ngIf="!isValid()">
                      
                    </div>
                  </div>

                </div><!-- /content-box -->

                <div class="col-md-4 side-column">
                  <h4>
                    <uxtemplate [uxHelper]="uxHelper" [key]="uxHelper.getUxcompKey('rhs.title')"></uxtemplate>
                  </h4>
                  <div>
                    <uxtemplate [uxHelper]="uxHelper" [key]="uxHelper.getUxcompKey('rhs.content')"></uxtemplate>
                  </div>
                </div><!-- /side-column -->

              </div>
            </div>
          </div><!-- /row -->
        </div><!-- /container -->
      </div><!-- /container-fluid -->


    </div>

    <app-sales-footer [uxHelper]="uxHelper"></app-sales-footer>
    <app-sales-disclaimer [uxHelper]="uxHelper"></app-sales-disclaimer>
  </ng-container>
</div>
<app-sales-closure></app-sales-closure>


<dev-message [uxHelper]="uxHelper" *ngIf="uxHelper.hasUxComposite()">
</dev-message>

