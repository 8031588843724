<div id="{{page}}" class="theme-legacy theme-{{uxHelper.getTheme()}} {{pageType}} {{pageCategory}}">
  <ng-container *ngIf="content?.content">
    <app-sales-header *ngIf="content?.options?.components?.salesHeader" [uxHelper]="uxHelper"></app-sales-header>

    <rawtemplate [html]="content.content"></rawtemplate>

    <app-sales-footer *ngIf="content?.options?.components?.salesFooter" [uxHelper]="uxHelper"></app-sales-footer>
    <app-sales-disclaimer *ngIf="content?.options?.components?.salesDisclaimer" [uxHelper]="uxHelper"></app-sales-disclaimer>
  </ng-container>
</div>
