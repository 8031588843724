/* src/app/member/peopleSearch/nameSearch/detail/miniReversePhone/miniReversePhone.component.scss */
.margin {
  margin: 100px 0;
}
.d-md-none {
  display: none;
}
.mini-reports-header {
  padding: 80px 40px 0;
  border-bottom: 1px solid #DCDCDC;
  margin: 0;
}
.mini-reports-header__desc h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  color: #276FCF;
}
.mini-reports-header__desc p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #525252;
}
.mini-reports-header__img {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.mini-reports-header__img .icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 140px;
  width: 150px;
}
.mini-reports-header__img .icon-wrapper > img {
  margin-bottom: 16px;
}
.mini-reports-header__img .icon-wrapper > img:last-child {
  margin-right: 20px;
  margin-bottom: 0;
}
.mini-reports-body {
  padding: 0 41px 96px;
  border-bottom: 1px solid #dcdcdc;
}
.mini-reports-body-diagram {
  height: 600px;
  position: relative;
}
.mini-reports-body-diagram__content {
  width: 950px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.mini-reports-body-diagram__content .info-item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 43%;
}
.mini-reports-body-diagram__content .info-item .item-text {
  width: 200px;
}
.mini-reports-body-diagram__content .info-item .item-text p {
  margin-bottom: 0;
}
.mini-reports-body-diagram__content .info-item .item-text p:nth-child(1) {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #525252;
  margin-bottom: 8px;
}
.mini-reports-body-diagram__content .info-item .item-text p:nth-child(3) {
  position: absolute;
}
.mini-reports-body-diagram__content .info-item img {
  margin-right: 10px;
  width: 70px;
}
.mini-reports-body-diagram__content .info-item .small-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 13px;
}
.mini-reports-body-diagram__content .info-item .smaller-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.mini-reports-body-diagram__content .info-item--birth .small-circle {
  background: #e8916a;
}
.mini-reports-body-diagram__content .info-item--birth .smaller-circle {
  background: #e8916a;
}
.mini-reports-body-diagram__content .info-item--birth .item-text p {
  text-align: right;
}
.mini-reports-body-diagram__content .info-item--age {
  transform: rotate(60deg);
}
.mini-reports-body-diagram__content .info-item--age .small-circle {
  background: #5b92db;
}
.mini-reports-body-diagram__content .info-item--age .smaller-circle {
  background: #5b92db;
}
.mini-reports-body-diagram__content .info-item--age .item-text {
  transform: rotate(-60deg) translate(-75px, 115px);
}
.mini-reports-body-diagram__content .info-item--age .item-text p {
  text-align: right;
}
.mini-reports-body-diagram__content .info-item--age img {
  transform: rotate(-60deg);
}
.mini-reports-body-diagram__content .info-item--location {
  transform: rotate(120deg);
}
.mini-reports-body-diagram__content .info-item--location .item-text {
  transform: rotate(-120deg) translate(77px, 114px);
}
.mini-reports-body-diagram__content .info-item--location .item-text p:first-child,
.mini-reports-body-diagram__content .info-item--location .item-text p:last-child,
.mini-reports-body-diagram__content .info-item--location .item-text p {
  text-align: left;
}
.mini-reports-body-diagram__content .info-item--location .small-circle {
  background: #a0e9dd;
}
.mini-reports-body-diagram__content .info-item--location .smaller-circle {
  background: #a0e9dd;
}
.mini-reports-body-diagram__content .info-item--location img {
  transform: rotate(-120deg);
}
.mini-reports-body-diagram__content .info-item--zodiac {
  transform: rotate(180deg);
}
.mini-reports-body-diagram__content .info-item--zodiac .item-text {
  transform: rotate(60deg) translate(40px, -36px);
}
.mini-reports-body-diagram__content .info-item--zodiac .item-text p:first-child,
.mini-reports-body-diagram__content .info-item--zodiac .item-text p:last-child,
.mini-reports-body-diagram__content .info-item--zodiac .item-text p {
  text-align: left;
}
.mini-reports-body-diagram__content .info-item--zodiac .small-circle {
  background: #b0a8e7;
}
.mini-reports-body-diagram__content .info-item--zodiac .smaller-circle {
  background: #b0a8e7;
}
.mini-reports-body-diagram__content .info-item--zodiac .item-text {
  transform: rotate(180deg);
}
.mini-reports-body-diagram__content .info-item--zodiac img {
  transform: rotate(180deg);
}
.mini-reports-body-diagram__content .info-item--phone {
  transform: rotate(240deg);
}
.mini-reports-body-diagram__content .info-item--phone .small-circle {
  background: #b6b7bc;
}
.mini-reports-body-diagram__content .info-item--phone .smaller-circle {
  background: #b6b7bc;
}
.mini-reports-body-diagram__content .info-item--phone .item-text {
  transform: rotate(120deg) translate(70px, -105px);
}
.mini-reports-body-diagram__content .info-item--phone .item-text p:first-child,
.mini-reports-body-diagram__content .info-item--phone .item-text p:last-child,
.mini-reports-body-diagram__content .info-item--phone .item-text p {
  text-align: left;
}
.mini-reports-body-diagram__content .info-item--phone img {
  transform: rotate(120deg);
}
.mini-reports-body-diagram__content .info-item--email {
  transform: rotate(-60deg);
  position: relative;
}
.mini-reports-body-diagram__content .info-item--email .small-circle {
  background: #f8da8a;
}
.mini-reports-body-diagram__content .info-item--email .smaller-circle {
  background: #f8da8a;
}
.mini-reports-body-diagram__content .info-item--email .item-text {
  transform: rotate(60deg) translate(-70px, -120px);
}
.mini-reports-body-diagram__content .info-item--email .item-text > div {
  width: 100%;
  position: absolute;
  max-height: 150px;
  overflow-y: auto;
}
.mini-reports-body-diagram__content .info-item--email .item-text > div.item-text-emails {
  width: auto;
}
.mini-reports-body-diagram__content .info-item--email .item-text > div p {
  text-align: right;
}
.mini-reports-body-diagram__content .info-item--email .item-text > div .btn-see-more a {
  cursor: pointer;
}
.mini-reports-body-diagram__content .info-item--email img {
  transform: rotate(60deg);
}
.mini-reports-body-diagram__back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}
.mini-reports-body-diagram__back img {
  margin: 0 auto;
  width: 350px;
}
.mini-reports-body-diagram__back p {
  margin: auto;
  margin-top: 340px;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #276FCF;
}
.mini-reports-body .linear-container {
  padding-top: 45px;
}
.mini-reports-body-phone,
.mini-reports-body-emails {
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 48px 8px 48px;
}
.mini-reports-body-phone .phone-item,
.mini-reports-body-emails .phone-item {
  margin: 0;
  border-bottom: 1px solid #DCDCDC;
  padding: 12px 120px 12px;
}
.mini-reports-body-phone .phone-item:last-child,
.mini-reports-body-emails .phone-item:last-child {
  border-bottom: none;
}
.mini-reports-body-phone .phone-item p,
.mini-reports-body-emails .phone-item p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #525252;
}
.mini-reports-body-phone .phone-item .phone-number,
.mini-reports-body-emails .phone-item .phone-number {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #276FCF;
}
.mini-reports-body-phone .email-address,
.mini-reports-body-emails .email-address {
  margin: 0 !important;
}
.mini-reports-body-phone .email-address hr,
.mini-reports-body-emails .email-address hr {
  border-color: #DCDCDC !important;
  margin: 0;
}
.mini-reports-body-phone .email-address hr.hr-transparent,
.mini-reports-body-emails .email-address hr.hr-transparent {
  border-color: transparent !important;
}
.mini-reports-body-phone .email-address .title,
.mini-reports-body-emails .email-address .title {
  font-size: 18px;
  line-height: 25px;
  color: #525252;
}
.mini-reports-body-phone .email-address div,
.mini-reports-body-emails .email-address div {
  padding: 8px;
}
.mini-reports-body-phone .email-address div p,
.mini-reports-body-emails .email-address div p {
  font-size: 18px;
  color: #276FCF;
}
.mini-reports-body-me {
  margin: 24px 0;
  display: flex;
  justify-content: flex-end;
}
.mini-reports-body-me .switch {
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  width: 256px;
  height: 76px;
  display: flex;
  padding: 24px;
  align-items: center;
}
.mini-reports-body-me .switch p {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #525252;
  margin: 0;
  margin-right: 16px;
}
.mini-reports-footer {
  padding: 89px 85px 0;
  margin: 0;
}
.mini-reports-footer > div:first-child {
  display: flex;
}
.mini-reports-footer > div:first-child > div {
  padding: 0 65px;
  flex-grow: 1;
}
.mini-reports-footer > div:first-child > div app-report-action,
.mini-reports-footer > div:first-child > div app-next-report {
  height: 100%;
}
.mini-reports-footer > div:last-child {
  margin: 0;
  display: flex;
  justify-content: center;
  padding: 37px 0;
}
.max-width {
  max-width: 70px;
}
@media (max-width: 1050px) {
  .mini-reports-body-diagram__content {
    width: 100%;
  }
  .mini-reports-body-diagram__content .info-item {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .d-sm-block {
    display: block;
  }
  .mini-reports-header {
    padding: 40px 40px 0;
  }
  .mini-reports-header__desc h2 {
    font-size: 24px;
    line-height: 28px;
  }
  .mini-reports-header__desc p {
    font-size: 16px;
    line-height: 21px;
  }
  .mini-reports-header__img .icon-wrapper {
    margin: 0;
    width: 75px;
    align-items: flex-end;
  }
  .mini-reports-header__img .icon-wrapper img {
    margin-bottom: 12px;
    width: 80%;
  }
  .mini-reports-header__img .icon-wrapper img:last-child {
    width: 99%;
    margin: 0;
  }
  .mini-reports-body-diagram__back img {
    width: 32%;
  }
  .mini-reports-body-diagram__back p {
    font-size: 28px;
    line-height: 32px;
  }
  .mini-reports-body-diagram__content .info-item {
    width: 98%;
  }
  .mini-reports-body-diagram__content .info-item .item-text {
    width: 160px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:first-child {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:last-child {
    font-size: 16px;
    margin-bottom: 0;
  }
  .mini-reports-body-phone .phone-item {
    padding: 12px 40px 12px;
  }
  .mini-reports-body-phone .phone-item p {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 4px;
  }
  .mini-reports-body-phone .phone-item .phone-number {
    font-size: 16px;
    line-height: 25px;
  }
  .mini-reports-body-me {
    margin: 16px 0;
  }
  .mini-reports-body-me .switch {
    padding: 24px 40px;
    width: 232px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mini-reports-body-me .switch p {
    font-size: 20px;
    line-height: 23px;
  }
  .mini-reports-footer {
    padding: 54px 30px 0;
  }
  .mini-reports-footer > div:first-child div {
    padding: 0 10px 0;
  }
  .mini-reports-footer > div:last-child {
    padding: 32px 0;
  }
}
@media (max-width: 900px) {
  .mini-reports-body-diagram__back img {
    width: 24%;
  }
  .mini-reports-body-diagram__back p {
    font-size: 21px;
    line-height: 24px;
    margin-top: 320px;
  }
  .mini-reports-body-diagram__content .info-item {
    width: 100%;
  }
  .mini-reports-body-diagram__content .info-item .item-text {
    width: 145px;
  }
}
@media (max-width: 767px) {
  .mini-reports-header {
    padding: 40px 20px 0;
  }
  .mini-reports-header__desc p {
    font-size: 12px;
    line-height: 14px;
  }
  .mini-reports-header__img .icon-wrapper {
    width: 55px;
  }
  .mini-reports-header__img .icon-wrapper img {
    margin-bottom: 12px;
    width: 80%;
  }
  .mini-reports-header__img .icon-wrapper img:last-child {
    width: 99%;
    margin: 0;
  }
  .mini-reports-body {
    padding: 40px 20px;
  }
  .mini-reports-body-diagram__back img {
    width: 25%;
  }
  .mini-reports-body-diagram__back p {
    font-size: 16px;
    line-height: 21px;
  }
  .mini-reports-body-diagram__content .info-item {
    width: 100%;
    top: 47%;
  }
  .mini-reports-body-diagram__content .info-item img {
    width: 30px;
  }
  .mini-reports-body-diagram__content .info-item .item-text {
    width: 25%;
  }
  .mini-reports-body-diagram__content .info-item .item-text p {
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:first-child {
    margin-bottom: 4;
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:last-child {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item--birth .item-text {
    transform: translate(12px, 40px);
  }
  .mini-reports-body-diagram__content .info-item--age .item-text {
    transform: rotate(-60deg) translate(-49px, 126px);
  }
  .mini-reports-body-diagram__content .info-item--location .item-text {
    transform: rotate(-120deg) translate(48px, 121px);
  }
  .mini-reports-body-diagram__content .info-item--zodiac .item-text {
    transform: rotate(180deg) translate(-13px, 40px);
  }
  .mini-reports-body-diagram__content .info-item--phone .item-text {
    transform: rotate(120deg) translate(45px, -33px);
  }
  .mini-reports-body-diagram__content .info-item--email .item-text {
    transform: rotate(60deg) translate(-38px, -39px);
  }
  .mini-reports-body-diagram__content .info-item--email .item-text p {
    text-align: right;
  }
  .mini-reports-body-phone .phone-item {
    padding: 8px 24px;
  }
  .mini-reports-body-phone .phone-item p,
  .mini-reports-body-phone .phone-item b {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 4px;
    word-break: break-all;
  }
  .mini-reports-body-phone .phone-item .phone-number {
    font-size: 10px;
    line-height: 12px;
  }
  .mini-reports-body-me {
    margin: 12px 0;
  }
  .mini-reports-body-me .switch {
    padding: 16px;
    width: unset;
    height: unset;
  }
  .mini-reports-body-me .switch p {
    font-size: 12px;
    line-height: 14px;
  }
  .mini-reports-footer {
    padding: 40px 20px 0;
  }
  .mini-reports-footer > div:first-child {
    display: block;
  }
  .mini-reports-footer > div:first-child div {
    padding: 0 10px 0;
  }
  .mini-reports-footer > div:first-child div:first-child {
    margin-bottom: 32px;
  }
  .mini-reports-footer > div:last-child {
    padding: 24px 0;
  }
}
@media (max-width: 660px) {
  .mini-reports-body-diagram__content .info-item {
    top: 46%;
  }
  .mini-reports-body-diagram__content .info-item img {
    width: 30px;
  }
  .mini-reports-body-diagram__content .info-item .item-text {
    width: 20%;
  }
  .mini-reports-body-diagram__content .info-item .item-text p {
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:first-child {
    margin-bottom: 4;
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:last-child {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item--birth .item-text {
    transform: translate(26px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--age .item-text {
    transform: rotate(-60deg) translate(-29px, 98px);
  }
  .mini-reports-body-diagram__content .info-item--location .item-text {
    transform: rotate(-120deg) translate(25px, 96px);
  }
  .mini-reports-body-diagram__content .info-item--zodiac .item-text {
    transform: rotate(180deg) translate(-29px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--phone .item-text {
    transform: rotate(120deg) translate(21px, -22px);
  }
  .mini-reports-body-diagram__content .info-item--email .item-text {
    transform: rotate(60deg) translate(-22px, -24px);
  }
}
@media (max-width: 590px) {
  .mini-reports-body {
    padding-top: 0;
  }
  .mini-reports-body-diagram {
    height: 480px;
  }
  .mini-reports-body-diagram__back {
    font-size: 14px;
  }
  .mini-reports-body-diagram__back img {
    width: 43%;
  }
  .mini-reports-body-diagram__back p {
    font-size: 18px;
    line-height: 21px;
    margin-top: 283px;
  }
  .mini-reports-body-diagram__content .info-item img {
    width: 30px;
    margin-left: -10px;
  }
  .mini-reports-body-diagram__content .info-item .item-text {
    width: 16%;
  }
  .mini-reports-body-diagram__content .info-item .item-text p {
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:first-child {
    margin-bottom: 4;
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:last-child {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item--birth .item-text {
    transform: translate(26px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--age .item-text {
    transform: rotate(-60deg) translate(-24px, 87px);
  }
  .mini-reports-body-diagram__content .info-item--location .item-text {
    transform: rotate(-120deg) translate(20px, 89px);
  }
  .mini-reports-body-diagram__content .info-item--zodiac .item-text {
    transform: rotate(180deg) translate(-29px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--phone .item-text {
    transform: rotate(120deg) translate(16px, -13px);
  }
  .mini-reports-body-diagram__content .info-item--email .item-text {
    transform: rotate(60deg) translate(-18px, -15px);
  }
}
@media (max-width: 505px) {
  .mini-reports-body {
    padding-top: 0;
  }
  .mini-reports-body-diagram {
    height: 420px;
  }
  .mini-reports-body-diagram__back img {
    width: 53%;
  }
  .mini-reports-body-diagram__back p {
    font-size: 17px;
    margin-top: 193px;
  }
  .mini-reports-body-diagram__content .info-item img {
    width: 27px;
    margin-left: -50px;
  }
  .mini-reports-body-diagram__content .info-item .small-circle {
    width: 8px;
    height: 8px;
  }
  .mini-reports-body-diagram__content .info-item .smaller-circle {
    width: 5px;
    height: 5px;
  }
  .mini-reports-body-diagram__content .info-item .item-text {
    width: 22%;
  }
  .mini-reports-body-diagram__content .info-item .item-text p {
    font-size: 9px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:first-child {
    margin-bottom: 0;
    font-size: 9px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:last-child {
    margin-bottom: 0;
    font-size: 9px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item--birth .item-text {
    transform: translate(-25px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--age .item-text {
    transform: rotate(-60deg) translate(-45px, 40px);
  }
  .mini-reports-body-diagram__content .info-item--location .item-text {
    transform: rotate(-120deg) translate(45px, 40px);
  }
  .mini-reports-body-diagram__content .info-item--zodiac .item-text {
    transform: rotate(180deg) translate(27px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--phone .item-text {
    transform: rotate(120deg) translate(40px, 24px);
  }
  .mini-reports-body-diagram__content .info-item--email .item-text {
    transform: rotate(60deg) translate(-50px, 18px);
  }
  .mini-reports-body-phone,
  .mini-reports-body-emails {
    padding: 15px 8px 15px;
  }
  .mini-reports-body-phone .phone-item,
  .mini-reports-body-emails .phone-item {
    padding: 8px 0;
  }
  .mini-reports-body-phone .phone-item > div,
  .mini-reports-body-emails .phone-item > div {
    padding: 0 8px;
  }
}
@media (max-width: 350px) {
  .mini-reports-body-diagram {
    height: 400px;
  }
  .mini-reports-body-diagram__back img {
    width: 53%;
  }
  .mini-reports-body-diagram__back p {
    font-size: 14px;
    margin-top: 218px;
  }
  .mini-reports-body-diagram__content .info-item img {
    width: 27px;
    margin-left: -60px;
  }
  .mini-reports-body-diagram__content .info-item .small-circle {
    width: 8px;
    height: 8px;
  }
  .mini-reports-body-diagram__content .info-item .smaller-circle {
    width: 5px;
    height: 5px;
  }
  .mini-reports-body-diagram__content .info-item .item-text {
    width: 24%;
  }
  .mini-reports-body-diagram__content .info-item .item-text p {
    font-size: 9px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:first-child {
    margin-bottom: 0;
    font-size: 9px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item .item-text p:last-child {
    margin-bottom: 0;
    font-size: 9px;
    line-height: 14px;
  }
  .mini-reports-body-diagram__content .info-item--birth .item-text {
    transform: translate(-25px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--age .item-text {
    transform: rotate(-60deg) translate(-39px, 18px);
  }
  .mini-reports-body-diagram__content .info-item--location .item-text {
    transform: rotate(-120deg) translate(37px, 27px);
  }
  .mini-reports-body-diagram__content .info-item--zodiac .item-text {
    transform: rotate(180deg) translate(27px, 33px);
  }
  .mini-reports-body-diagram__content .info-item--phone .item-text {
    transform: rotate(120deg) translate(36px, 40px);
  }
  .mini-reports-body-diagram__content .info-item--email .item-text {
    transform: rotate(60deg) translate(-34px, 32px);
  }
}
/*# sourceMappingURL=miniReversePhone.component.css.map */
