<div class="col-xs-12 col-sm-12">
  <div class="row">
    <div class="col-sm-6">
      <dl>
        <dt>Name</dt>
        <dd>{{business.businessName}}</dd>
      </dl>
    </div>
    <div class="col-sm-6">
      <dl>
        <dt>Type</dt>
        <dd>{{business.sourceType}}</dd>
      </dl>
    </div>
  </div>
</div>
<div class="col-xs-12">
  <div class="row">
    <div *ngIf="business.status" class="col-xs-6 col-sm-3">
      <dl>
        <dt>Status</dt>
        <dd>{{business.status}}</dd>
      </dl>
    </div>
    <div *ngIf="business.filingNumber" class="col-xs-6 col-sm-3">
      <dl>
        <dt>Filing Number</dt>
        <dd>{{business.filingNumber}}</dd>
      </dl>
    </div>
    <div *ngIf="business.filingDate" class="col-xs-6 col-sm-3">
      <dl>
        <dt>Filing Date</dt>
        <dd>{{business.filingDate}}</dd>
      </dl>
    </div>
    <div *ngIf="business.filingState" class="col-xs-6 col-sm-3">
      <dl>
        <dt>Filing State</dt>
        <dd>{{business.filingState}}</dd>
      </dl>
    </div>
    <div *ngIf="business.incorpDate" class="col-xs-6 col-sm-3">
      <dl>
        <dt>Incorp Date</dt>
        <dd>{{business.incorpDate}}</dd>
      </dl>
    </div>
    <div *ngIf="business.url" class="col-xs-6 col-sm-3">
      <dl>
        <dt>Website</dt>
        <dd>{{business.url}}</dd>
      </dl>
    </div>
  </div>

  <ng-container *ngFor="let name of business.names">
    <div class="row">
      <div class="col-xs-6 col-sm-3">
        <dl>
          <dt>Title</dt>
          <dd>{{name.title}}</dd>
        </dl>
      </div>
      <div class="col-xs-6 col-sm-3">
        <dl>
          <dt>First Name</dt>
          <dd>{{name.fName}}</dd>
        </dl>
      </div>
      <div class="col-xs-6 col-sm-3">
        <dl>
          <dt>Middle Name</dt>
          <dd>{{name.mName}}</dd>
        </dl>
      </div>
      <div class="col-xs-6 col-sm-3">
        <dl>
          <dt>Last Name</dt>
          <dd>{{name.lName}}</dd>
        </dl>
      </div>
    </div>
  </ng-container>

  <ng-container *ngFor="let address of business.addresses">
    <div *ngIf="address" class="row">
      <div class="col-sm-3">
        <!-- mapEmbed -->
        <div *ngIf="address" class="mapouter">
          <div class="gmap_canvas">
            <a [href]="getGoogleMapUrl(address)" (click)="click($event,{newWindow:true})">
              <img *ngIf="address?.latitude && address?.longitude" [src]="getOsmMapTile(address)">
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-9">
        <dl>
          <dt>
            {{address.sourceType}}
          </dt>
          <dd>
            {{address.type}}
          </dd>
        </dl>
        <dl>
          <dt>Address</dt>
          <dd>
            {{address.fullAddress}}
          </dd>
        </dl>
      </div>
    </div>
  </ng-container>
</div>
