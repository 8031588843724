<div class="row" [class.bottom-border]="bottomLine">
  <div class="col-xs-3 col-sm-2 pics" *ngIf="isIDI">
    <img *ngIf="criminalRecord.images?.length" [src]="convertedCriminalPhotoUrl(criminalRecord.images[0]['name'])">
    <img *ngIf="!criminalRecord.images?.length" src="/assets/common/pr1/img/icon-person.png">
  </div>

  <div class="col-xs-3 col-sm-2 pics" *ngIf="!isIDI">
    <img *ngIf="criminalRecord.images?.length" [src]="criminalRecord.images[0]">
    <img *ngIf="!criminalRecord.images?.length" src="/assets/common/pr1/img/icon-person.png">
  </div>

  <ng-container *ngIf="isIDI">
    <div class="col-xs-9 col-sm-10">
      <div class="row">
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Name'" [value]="criminalRecord.fullName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Date of Birth'" [value]="criminalRecord.dob"></app-member-people-search-value>
        <div class="clearfix visible-xs-block"></div>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Age'" [value]="criminalRecord.age"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Gender'" [value]="criminalRecord.gender"></app-member-people-search-value>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isIDI">
    <div class="col-xs-9 col-sm-10">
      <div class="row">
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-6'" [title]="'Case Number'" [value]="criminalRecord.nullId?null:criminalRecord.id"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-6'" [title]="'Name'" [value]="criminalRecord.fName + ' ' + criminalRecord.mName + ' ' + criminalRecord.lName"></app-member-people-search-value>
        <div class="clearfix visible-xs-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-6'" [title]="'Offense'" [value]="criminalRecord.offenseDescription"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-6'" [title]="'Case Type'" [value]="criminalRecord.caseType"></app-member-people-search-value>
    </div>
  </div>
  </ng-container>

  <ng-container *ngIf="isIDI">
    <div class="col-xs-12">
      <div class="row">
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Source State'" [value]="criminalRecord.state"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Category'" [value]="criminalRecord.category"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Birth State'" [value]="criminalRecord.birthState"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Age'" [value]="criminalRecord.age"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Hair Color'" [value]="criminalRecord.hair"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Eye Color'" [value]="criminalRecord.eyes"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Height'" [value]="criminalRecord.height"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Weight'" [value]="criminalRecord.weight"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Race'" [value]="criminalRecord.race"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Scars'" [value]="criminalRecord.scarMarks"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Sex'" [value]="criminalRecord.sex"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Skin Tone'" [value]="criminalRecord.skinTone"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Ethnicity'" [value]="criminalRecord.ethnicity"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Body Build'" [value]="criminalRecord.bodyBuild"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Military Service'" [value]="criminalRecord.militaryService"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Citizenship'" [value]="criminalRecord.citizenship"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Status Remarks'" [value]="criminalRecord.statusRemarks"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'First name'" [value]="criminalRecord.fName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Last name'" [value]="criminalRecord.lName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Middle name'" [value]="criminalRecord.mName"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Suffix'" [value]="criminalRecord.suffix"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Prefix'" [value]="criminalRecord.prefix"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-3 col-sm-3'" [title]="'Aka flag'" [value]="criminalRecord.akaFlag"></app-member-people-search-value>
        
        <app-member-people-search-value *ngIf="!criminalRecord.akas?.length" [classes]="'col-xs-12'" [title]="''" [value]="'Akas'"></app-member-people-search-value>
        <app-member-people-search-value *ngIf="criminalRecord.akas?.length" [classes]="'col-xs-12'" [title]="'Akas'" [value]="criminalRecord.akas[0].data"></app-member-people-search-value>
        <app-member-people-search-value *ngFor="let aka of criminalRecord.akas.slice(1)" [classes]="'col-xs-12'" [title]="''" [value]="aka.data"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>

        <app-member-people-search-value [classes]="'col-xs-4 col-sm-4'" [title]="'Drivers License Expiration Year'" [value]="criminalRecord.driverLicense?.expYear"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-4 col-sm-4'" [title]="'Drivers License Number'" [value]="criminalRecord.driverLicense?.number"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-4 col-sm-4'" [title]="'Drivers License State'" [value]="criminalRecord.driverLicense?.state"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>

        <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'Sex Offender Id'" [value]="criminalRecord.sexOffender?.id"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'Sex Offender Database Id'" [value]="criminalRecord.sexOffender?.databaseId"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'Sex Offender Register Date'" [value]="criminalRecord.sexOffender?.registerDate"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'Sex Offender Risk Level'" [value]="criminalRecord.sexOffender?.riskLevel"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'Sex Offender Classification'" [value]="criminalRecord.sexOffender?.classification"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>

        <app-member-people-search-value *ngIf="!criminalRecord.vehicle.length" [classes]="'col-xs-12'" [title]="'Vehicle'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngIf="criminalRecord.vehicle.length" [classes]="'col-xs-12'" [title]="''" [value]="'Vehicle'"></app-member-people-search-value>
        <ng-container *ngFor="let vehicle of criminalRecord.vehicle">
          <app-member-people-search-value [classes]="'col-xs-3 col-sm-4'" [title]="''" [value]="vehicle"></app-member-people-search-value>
        </ng-container>
        <div class="clearfix visible-xs-block visible-sm-block"></div>

        <app-member-people-search-value *ngIf="!criminalRecord.bodyMark.length" [classes]="'col-xs-12'" [title]="'Body Mark'" [value]="''"></app-member-people-search-value>
        <app-member-people-search-value *ngIf="criminalRecord.bodyMark.length" [classes]="'col-xs-12'" [title]="''" [value]="'Body Mark'"></app-member-people-search-value>
        <ng-container *ngFor="let bodyMark of criminalRecord.bodyMark">
          <app-member-people-search-value [classes]="'col-xs-3 col-sm-4'" [title]="'Body Mark Item'" [value]="bodyMark.item"></app-member-people-search-value>
          <app-member-people-search-value [classes]="'col-xs-3 col-sm-4'" [title]="'Body Mark Description'" [value]="bodyMark.description"></app-member-people-search-value>
        </ng-container>
        <div class="clearfix visible-xs-block visible-sm-block"></div>

        <app-member-people-search-value *ngIf="!criminalRecord.addresses?.length" [classes]="'col-xs-12'" [title]="'Address'" [value]="''"></app-member-people-search-value>
        <div class="col-xs-12" *ngIf="criminalRecord.addresses?.length">
          <div class="row" *ngFor="let address of criminalRecord.addresses">
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'Address'" [value]="address.data"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'Date Range'" [value]="address.dateRange"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-4'" [title]="'County'" [value]="address.county"></app-member-people-search-value>
          </div>
        </div>
        
        <app-member-people-search-value *ngIf="!criminalRecord.offense.length" [classes]="'col-xs-12'" [title]="'Offense'" [value]="''"></app-member-people-search-value>
        <div class="col-sm-12" *ngFor="let offense of criminalRecord.offense">
          <div class="row bottom-border">
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Case Number'" [value]="offense.caseNumber"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Charges Filed Date'" [value]="offense.chargesFiledDate"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Date'" [value]="offense.date"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Code'" [value]="offense.code"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Location'" [value]="offense.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Description'" [value]="offense.description"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Statute'" [value]="offense.statute"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Class'" [value]="offense.class"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Degree'" [value]="offense.degree"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Level'" [value]="offense.level"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Ncic Code'" [value]="offense.ncicCode"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Counts'" [value]="offense.counts"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Plea'" [value]="offense.plea"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Conviction State'" [value]="offense.conviction?.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Conviction Date'" [value]="offense.conviction?.date?.data"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Warrant'" [value]="offense.warrant?.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Supervision'" [value]="offense.supervision?.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Commitment'" [value]="offense.commitment?.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Disposition'" [value]="offense.disposition?.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Amended Disposition'" [value]="offense.amendedDisposition?.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Arrest'" [value]="offense.arrest?.location"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Sentence'" [value]="offense.sentence"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Probation'" [value]="offense.probation"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Suspended'" [value]="offense.suspended"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Court'" [value]="offense.court"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Court Costs'" [value]="offense.courtCosts"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Case Type'" [value]="offense.caseType"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Fines'" [value]="offense.fines"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'County or Jurisdiction'" [value]="offense.countyOrJurisdiction"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Release Date'" [value]="offense.releaseDate"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'FBI Number'" [value]="offense.fbiNumber"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Booking Number'" [value]="offense.bookingNumber"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'DL Number'" [value]="offense.dlNumber"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Amended Charge'" [value]="offense.amendedCharge"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Amended Case Type'" [value]="offense.amendedCaseType"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Status'" [value]="offense.status"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Status Remarks'" [value]="offense.statusRemarks"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Restitution'" [value]="offense.restitution"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Comments'" [value]="offense.comments"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Victim Gender'" [value]="offense.victimGender"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Victim Age'" [value]="offense.victimAge"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Photo Name'" [value]="offense.photoName"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Category'" [value]="offense.category"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Source State'" [value]="offense.sourceState"></app-member-people-search-value>
            <app-member-people-search-value [classes]="'col-xs-12 col-sm-6'" [title]="'Source Name'" [value]="offense.sourceName"></app-member-people-search-value>
            <div class="clearfix visible-xs-block visible-sm-block"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isIDI">
    <div class="col-xs-12">
      <div class="row">
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-6'" [title]="'Court'" [value]="criminalRecord.court"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-6'" [title]="'Court County'" [value]="criminalRecord.courtCounty"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Offense Date'" [value]="criminalRecord.offenseDate"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Date of Birth'" [value]="criminalRecord.dob"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Age'" [value]="criminalRecord.age"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Race'" [value]="criminalRecord.race"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Skin Tone'" [value]="criminalRecord.skinTone"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Sex'" [value]="criminalRecord.sex"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Height'" [value]="criminalRecord.height"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Weight'" [value]="criminalRecord.weight"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Hair'" [value]="criminalRecord.hair"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Eyes'" [value]="criminalRecord.eyes"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Scar Marks'" [value]="criminalRecord.scarMarks"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Offense Code'" [value]="criminalRecord.offenseCode"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Charge Filed'" [value]="criminalRecord.chargesFileDate"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'County'" [value]="criminalRecord.county"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'State'" [value]="criminalRecord.state"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Source'" [value]="criminalRecord.source"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Conviction Date'" [value]="criminalRecord.convictionDate"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Conviction Place'" [value]="criminalRecord.convictionPlace"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Disposition'" [value]="criminalRecord.disposition"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Disposition Date'" [value]="criminalRecord.dispositionDate"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Sentenced'" [value]="criminalRecord.sentenced"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Fine'" [value]="criminalRecord.fine | currency : 'USD' : 'symbol' : '1.0'"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Plea'" [value]="criminalRecord.plea"></app-member-people-search-value>
        <app-member-people-search-value [classes]="'col-xs-6 col-sm-3'" [title]="'Probation Date'" [value]="criminalRecord.probationDate"></app-member-people-search-value>
        <div class="clearfix visible-xs-block visible-sm-block"></div>
        <app-member-people-search-value *ngFor="let aka of criminalRecord.akas" [classes]="'col-xs-6 col-sm-3'" [title]="'AKA'" [value]="aka"></app-member-people-search-value>
      </div>
    </div>
  </ng-container>
</div>
