/* src/app/app.component.scss */
.toast-wrapper {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 200px;
  height: 100vh;
  z-index: 1000;
}
.toast-wrapper .toastr {
  border-radius: 6px;
  padding: 8px 16px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  margin-bottom: 16px;
  width: 200px;
  transition: all 0.2s;
  cursor: pointer;
}
.toast-wrapper .toastr.success {
  background-color: #81b34c;
}
.toast-wrapper .toastr.error {
  background-color: #ff4b55;
}
.toast-wrapper .toastr__title {
  margin: 0;
  margin-bottom: 0.5em;
  font-size: 18px;
}
.toast-wrapper .toastr__content {
  margin: 0;
  font-size: 16px;
}
.toast-wrapper .toastr:hover {
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
}
/*# sourceMappingURL=app.component.css.map */
